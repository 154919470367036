import React from "react";
import { Modal } from "react-bootstrap";
import Button from "../../Button";

import Image from "next/image";
import Link from "next/link";
import styles from "./styles.module.scss";
import Container from "../../../Components/Container";
import SVG from "react-inlinesvg";
import UseAuth from "../../../Contexts/Auth/useAuth";
import PurchaseModal from "../../../Components/CartComponent/PurchaseModal";
import UseUtil from '../../../Contexts/Util/useUtil'

const CartModal = ({
  cartList,
  contractAddress,
  contract,
  sum,
  usdPrice,
  onHide,
  removeCartById,
  onRemoveData,
  currentCurrency,
  convertedPrice,
  purchaseModal,
  setPurchaseModal,
}) => {
  const { authState } = UseAuth();
  const {
    showConnectWalletPopup
  } = UseUtil()
  return (
    <Container>
      <div className={`${styles.content}`}>
        <h3>Your Cart</h3>
        <div>
          <button onClick={() => onHide()}>
            <SVG
              src="/assets/ResponsiveImages/fi_Close.svg"
              width="20px"
              height="20px"
            />
          </button>
        </div>
      </div>
      <hr />
      {cartList?.length > 0 ? (
        <div className={`${styles.cart_separate}`}>
          {/* <h3 className={`${styles.cart_heading}`}>Your Cart</h3> */}

          <div className={`${styles.clearlink_separate} mt-3`}>
            <h4 className={`${styles.numberofitem}`}>
              {cartList?.length} Items
            </h4>

            {/* <Link href="#"> */}
            <a className={`${styles.clearlink}`} onClick={() => onRemoveData()}>
              Clear All
            </a>
            {/* </Link> */}
          </div>

          <div className={`${styles.cart_data}`}>
            {cartList?.map((data, key) => {
              return (
                <div className={`${styles.cart_box}`}>
                  <div className={`${styles.cart_left}`}>
                    <div className={`${styles.cart_img}`}>
                      {data.asset?.file_type?.includes("video/") ? (
                        <Link
                          href={`${
                            data.token &&
                            data?.type !== "gallery" &&
                            data?.type !== "giveaway" &&
                            !(
                              data?.for_giveaway === 1 &&
                              data?.giveaway &&
                              data?.giveaway?.winner_announced === 0 &&
                              data?.metadata?.is_listed == 0
                            )
                              ? `/token/${contract[contractAddress]}:${data.token}`
                              : (data?.for_giveaway === 1 &&
                                  data?.giveaway &&
                                  data?.giveaway?.winner_announced === 0 &&
                                  data?.metadata?.is_listed == 0) ||
                                data?.type === "giveaway"
                              ? `/giveaway/${contract[contractAddress]}:${data.token}`
                              : data?.type === "gallery"
                              ? `/gallery/${contract[contractAddress]}:${data.token}`
                              : "#"
                          }`}
                        >
                          <a className={`${styles.imageContainer}`}>
                            <Image
                              src={
                                data.thumbnail
                                  ? data.thumbnail?.home_page_listings
                                  : "/assets/img/covers/cover1.jpg"
                              }
                              width={65}
                              height={65}
                              alt="Cartimg"
                            />
                          </a>
                        </Link>
                      ) : data.asset?.file_type?.includes("audio/") ? (
                        <Link
                          href={`${
                            data.token &&
                            data?.type !== "gallery" &&
                            data?.type !== "giveaway" &&
                            !(
                              data?.for_giveaway === 1 &&
                              data?.giveaway &&
                              data?.giveaway?.winner_announced === 0 &&
                              data?.metadata?.is_listed == 0
                            )
                              ? `/token/${contract[contractAddress]}:${data.token}`
                              : (data?.for_giveaway === 1 &&
                                  data?.giveaway &&
                                  data?.giveaway?.winner_announced === 0 &&
                                  data?.metadata?.is_listed == 0) ||
                                data?.type === "giveaway"
                              ? `/giveaway/${contract[contractAddress]}:${data.token}`
                              : data?.type === "gallery"
                              ? `/gallery/${contract[contractAddress]}:${data.token}`
                              : "#"
                          }`}
                        >
                          <a className={`${styles.imageContainer}`}>
                            <Image
                              src={
                                data.thumbnail
                                  ? data.thumbnail?.home_page_listings
                                  : "/assets/img/image-loader.gif"
                              }
                              width={65}
                              height={65}
                              alt="Cartimg"
                            />
                          </a>
                        </Link>
                      ) : (
                        <Link
                          href={`${
                            data.token &&
                            data?.type !== "gallery" &&
                            data?.type !== "giveaway" &&
                            !(
                              data?.for_giveaway === 1 &&
                              data?.giveaway &&
                              data?.giveaway?.winner_announced === 0 &&
                              data?.metadata?.is_listed == 0
                            )
                              ? `/token/${contract[contractAddress]}:${data.token}`
                              : (data?.for_giveaway === 1 &&
                                  data?.giveaway &&
                                  data?.giveaway?.winner_announced === 0 &&
                                  data?.metadata?.is_listed == 0) ||
                                data?.type === "giveaway"
                              ? `/giveaway/${contract[contractAddress]}:${data.token}`
                              : data?.type === "gallery"
                              ? `/gallery/${contract[contractAddress]}:${data.token}`
                              : "#"
                          }`}
                        >
                          <a className={`${styles.imageContainer}`}>
                            <Image
                              src={
                                data?.asset
                                  ? data.cardSize === "sm"
                                    ? data.metadata?.is_collection === 0
                                      ? data.asset.src?.home_page_listings
                                      : data.thumbnail?.home_page_listings
                                    : data.metadata?.is_collection === 0
                                    ? data.asset.src?.home_page_listings
                                    : data.thumbnail?.home_page_listings
                                  : "/assets/img/image-loader.gif"
                              }
                              width={65}
                              height={65}
                              alt="Cartimg"
                            />
                          </a>
                        </Link>
                      )}
                    </div>

                    <div className={`${styles.cart_details}`}>
                      <h2 className={`${styles.cart_title}`}>{data?.title}</h2>
                      <p className={`${styles.cart_category}`}>
                      {data?.owned_by?.username
                    ? data?.owned_by?.username
                    : data?.owned_by?.public_address.substring(0, 10)}
                      </p>
                      <p className={`${styles.cart_description}`}>
                        {data?.metadata?.royalty}% royalty goes to original
                        creator
                      </p>
                    </div>
                  </div>

                  <div className={`${styles.cart_right}`}>
                    <a onClick={() => removeCartById(data)}>
                      <svg
                        width="24"
                        height="25"
                        viewBox="0 0 24 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M18 6.5L6 18.5"
                          stroke="#CCCCCC"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M6 6.5L18 18.5"
                          stroke="#CCCCCC"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </a>

                    <div className={`${styles.priceseparate}`}>
                      <p className={`${styles.pricevalue}`}>
                        {parseFloat(data.metadata?.converted?.price).toFixed(2)}
                      </p>
                      <p className={`${styles.pricelabel}`}>
                        {data.metadata?.converted?.currency}
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          <div className={`${styles.price_separate}`}>
            <h4 className={`${styles.totalprice}`}>Total Price</h4>

            <div className={`${styles.priceseparate}`}>
              <p className={`${styles.pricevalue}`}>
                {parseFloat(sum).toFixed(4)}
              </p>
              <p className={`${styles.pricelabel}`}>
                {cartList?.[0]?.metadata?.converted?.currency}
              </p>
            </div>
          </div>

          <div className={`${styles.price_separate}`}>
            <h4 className={`${styles.totalprice}`}>&nbsp;</h4>

            <div className={`${styles.priceseparate}`}>
              <p className={`${styles.pricevalue}`}>
                {parseFloat(usdPrice).toFixed(2)}
              </p>
              <p className={`${styles.pricelabel}`}>USD</p>
            </div>
          </div>

          <div className={`${styles.btn_separate}`}>
            <Button
              tag={"md"}
              onClick={() => {
                authState?.isAuthenticated
                  ? setPurchaseModal(true)
                  : showConnectWalletPopup();
              }}
            >
              Proceed to Purchase
            </Button>
          </div>
        </div>
      ) : (
        <div className={`${styles.EmptyCart}`}>
          <Image
            src="/assets/EmptyCart.svg"
            width={252}
            height={190}
            alt="EmptyCart"
          />
          <h4 className={`${styles.Headings}`}>Your Cart is Empty</h4>
          <h6 className={`${styles.SubHeadings}`}>
            Looks like you haven’t added anything to your cart yet
          </h6>
        </div>
      )}

      <PurchaseModal
        show={purchaseModal}
        onHide={() => {
          setPurchaseModal(false);
        }}
        cartItem={cartList}
        total={sum}
        currentCurrency={currentCurrency}
        convertedPrice={convertedPrice}
        onRemoveData={onRemoveData}
      />
    </Container>
  );
};

export default CartModal;
