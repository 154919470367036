import React from 'react'
import Skeleton from 'react-loading-skeleton'
import styles from './SearchingLoader.module.scss'
const SearchingLoader = () => {
  return (
    <div className={`${styles.searching_listbox}`}>
      <div className={`${styles.searching_titlebox}`}>
          <Skeleton style={{ height: "20px", width: "100px" }}/>
      </div>
      <div className={`${styles.searching_cardbox}`}>
        <div className={`${styles.searching_box}`}>
          <Skeleton style={{ height: "40px", width: "40px" }} circle={true} />
          <div className={`${styles.searching_details}`}>
            <Skeleton style={{height: "17px", width: "100%" }}/>
            <Skeleton style={{ height: "13px", width: "100%" }}/>
          </div>
        </div>
      </div>
      
      <div className={`${styles.searching_cardbox}`}>
        <div className={`${styles.searching_box}`}>
          <Skeleton style={{ height: "40px", width: "40px" }} circle={true} />
          <div className={`${styles.searching_details}`}>
            <Skeleton style={{height: "17px", width: "100%" }}/>
            <Skeleton style={{ height: "13px", width: "100%" }}/>
          </div>
        </div>
      </div>


      <div className={`${styles.searching_titlebox}`}>
          <Skeleton style={{ height: "20px", width: "100px" }}/>
      </div>
      <div className={`${styles.searching_cardbox}`}>
        <div className={`${styles.searching_box}`}>
          <Skeleton style={{ height: "40px", width: "40px" }} circle={true} />
          <div className={`${styles.searching_details}`}>
            <Skeleton style={{height: "17px", width: "100%" }}/>
            <Skeleton style={{ height: "13px", width: "100%" }}/>
          </div>
        </div>
      </div>
      
      <div className={`${styles.searching_cardbox}`}>
        <div className={`${styles.searching_box}`}>
          <Skeleton style={{ height: "40px", width: "40px" }} circle={true} />
          <div className={`${styles.searching_details}`}>
            <Skeleton style={{height: "17px", width: "100%" }}/>
            <Skeleton style={{ height: "13px", width: "100%" }}/>
          </div>
        </div>
      </div>

      <div className={`${styles.searching_titlebox}`}>
          <Skeleton style={{ height: "20px", width: "100px" }}/>
      </div>
      <div className={`${styles.searching_cardbox}`}>
        <div className={`${styles.searching_box}`}>
          <Skeleton style={{ height: "40px", width: "40px" }} circle={true} />
          <div className={`${styles.searching_details}`}>
            <Skeleton style={{height: "17px", width: "100%" }}/>
            <Skeleton style={{ height: "13px", width: "100%" }}/>
          </div>
        </div>
      </div>
      
      <div className={`${styles.searching_cardbox}`}>
        <div className={`${styles.searching_box}`}>
          <Skeleton style={{ height: "40px", width: "40px" }} circle={true} />
          <div className={`${styles.searching_details}`}>
            <Skeleton style={{height: "17px", width: "100%" }}/>
            <Skeleton style={{ height: "13px", width: "100%" }}/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SearchingLoader