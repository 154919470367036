import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import Button from "../../Button";
import { useWeb3React } from "@web3-react/core";
import Image from "next/image";
import Link from "next/link";
import styles from "./styles.module.scss";
import UseAuth from "../../../Contexts/Auth/useAuth";
import UseUser from "../../../Contexts/User/useUser";
import { getUserWalletBalance } from "../../../lib/User";
import { cartLogHistory, buyCartNft } from "../../../lib/NFT";
import { formatEther, formatUnits, parseEther } from "@ethersproject/units";
import SwitchNetwork from "../../../Hooks/SwitchNetwork";
import { Contract } from "@ethersproject/contracts";
import contract from "../../../Configs/Contract";
import getChainID from "../../../Hooks/getChainID";
import blockChainData from "../../../Hooks/blockChainData";
import Script from "next/script";
import UseUtil from "../../../Contexts/Util/useUtil";
import Loader from "react-loader-spinner";
import Swal from "sweetalert2";
import { useRouter } from "next/router";

// import { formatEther, formatUnits, parseEther } from '@ethersproject/units';

const PurchaseModal = ({
  cartItem,
  show,
  onHide,
  props,
  total,
  currentCurrency,
  convertedPrice,
  onRemoveData,
}) => {
  const { hideTransactionPopup, showTransactionPopup } = UseUtil();
  const [currentBalance, setCurrentBalance] = useState(0);
  const { authState } = UseAuth();
  const { userState } = UseUser();
  const context = useWeb3React();
  const [walletDetail, setWalletDetail] = useState({});
  const currency = cartItem?.[0]?.currency;
  const [checked, setChecked] = useState(false);
  const [showErrorMsg, setShowErrorMessage] = useState(false);
  const [token, setToken] = useState([]);
  const [publicAddress, setPublicAddress] = useState([]);
  const router = useRouter();
  const [sum, setSum] = useState(0)
  const [updateGems, setUpdateGems] = React.useState(null);

  const { library, account, chainId } = context;
  const chainIDOfNFT = getChainID(currency);
  const [contractAddress, setContractAddress] = React.useState();
  const [contractABIKey, setContractABIKey] = React.useState();

  const [contractAddressMultiple, setContractAddressMultiple] =
    React.useState();
  const [contractABIKeyMultiple, setContractABIKeyMultiple] = React.useState();
  const [onloading, setLoading] = React.useState(false);

  let serviceFee = (parseFloat(total) * parseFloat(2.5)) / 100;
  let totalPrice = parseFloat(total) + parseFloat(serviceFee);

  useEffect(() => {
    if (userState) {
      setUpdateGems(userState?.userProfileData?.gems);
    }
  }, [userState]);

  React.useEffect(() => {
    if (chainIDOfNFT) {
      let getBlockChainDataValue = blockChainData(chainIDOfNFT);
      setContractAddress(getBlockChainDataValue.contractAddress);
      setContractABIKey(getBlockChainDataValue.contractABIKey);

      setContractAddressMultiple(
        getBlockChainDataValue.contractAddressMultiple
      );
      setContractABIKeyMultiple(getBlockChainDataValue.contractABIKeyMultiple);
    }
  }, [chainIDOfNFT]);

  React.useEffect(() => {
    if (
      authState?.isAuthenticated &&
      library &&
      userState?.userProfileData?.wallet_type !== "custom"
    ) {
      (async () => {
        let bal = await library.getBalance(account);
        setCurrentBalance(formatEther(bal).substring(0, 7));
      })();
    } else if (
      authState?.isAuthenticated &&
      userState?.userProfileData?.wallet_type === "custom"
    ) {
      (async () => {
        const req = {
          address: userState?.userProfileData?.public_address,
        };
        const response = await getUserWalletBalance(req);
        if (response?.status === 200) {
          setWalletDetail(response?.data?.data?.etherium_balance);
          setCurrentBalance(response?.data?.data?.polygon_balance);
        }
      })();
    }
  }, [authState?.isAuthenticated || library, userState?.userProfileData]);

  const onPurchaseItem = async () => {
    const tokens = [];
    const address = [];
    if (checked === false) {
      setShowErrorMessage(true);
      return true;
    } else {
      cartItem?.map((item) => {
        tokens.push(item?.token);
        address.push(item?.owned_by?.public_address);
      });
      setToken(tokens);
      setPublicAddress(address);
      if (userState?.userProfileData?.wallet_type !== "custom") {
        let requiredBlockChain = currency === "MATIC" ? "polygon" : "ethereum";
        let requiredChainID =
          currency === "MATIC"
            ? process.env.NEXT_PUBLIC_POLYGON_CHAIN_ID
            : process.env.NEXT_PUBLIC_CHAIN_ID;
        if (chainId !== requiredChainID) {
          onHide();
          setChecked(false);
        }
        let isValidBlockChain = await SwitchNetwork(
          chainId,
          requiredBlockChain
        );
        if (isValidBlockChain) {
          if (checked === false) {
            setShowErrorMessage(true);
          } else {
            try {
              setShowErrorMessage(false);
              setLoading(true);
              showTransactionPopup("NFT purchase in progress");
              let useracc = account;

              let mycon = new Contract(
                contract[contractAddress],
                contract[contractABIKey],
                library.getSigner()
              );

              // let data = await mycon.setApprovalForAll('0x7a74a5789B1A34F7dE2C2768BA9b6162d6d077bc', true)
              let myconMultiple = new Contract(
                contract[contractAddressMultiple],
                contract[contractABIKeyMultiple],
                library.getSigner()
              );
              let fee = await mycon.serviceFee();
              let price = total;
              let serviceFee = formatUnits(fee, 0);
              let finalValueFormat = 0;
              cartItem?.map((item, key) => {
                let servicePercentage =
                  (parseFloat(item?.metadata?.converted?.price) *
                    parseFloat(serviceFee)) /
                  1000;
                let finalValue =
                  parseFloat(item?.metadata?.converted?.price) +
                  parseFloat(servicePercentage);
                finalValueFormat =
                  parseFloat(finalValueFormat) +
                  parseFloat(parseFloat(finalValue).toFixed(9));
              });
              let value = parseEther(finalValueFormat.toString());
              let tx = await myconMultiple
                .buyMultiple(address, useracc, tokens, {
                  value,
                  gasLimit: 300000,
                })
                .then(async (data) => {
                  const receipt = await data.wait(1);
                  if (receipt) {
                    onHide();
                    setLoading(false);
                    setChecked(false);
                    hideTransactionPopup();
                  }
                  let logObj = {};
                  logObj.operation = "BOUGHT";
                  logObj.token_ids = tokens;
                  logObj.tx_hash = receipt.transactionHash;
                  logObj.blockchain =
                    currency === "MATIC" ? "polygon" : "ethereum";
                  let logresp = await cartLogHistory(logObj);

                  const script = `
                rdt('track', 'Purchase', {
                  "currency": ${currency},
                  "itemCount": 1,
                  "transactionId": ${receipt.transactionHash},
                  "value": ${value}
                });
              `;

              let datas = cartItem?.reduce(
                (accumulator, currentValue) => {
                  const price = currentValue?.metadata?.converted?.price > 50 ? currentValue?.metadata?.converted?.price : 50
                  return (
                    accumulator + price
                  );
                },
                0
              );
              setSum(datas);
                  <Script
                    strategy="lazyOnload"
                    dangerouslySetInnerHTML={{ __html: script }}
                  />;
                  Swal.fire({
                    icon: "success",
                    title: "NFT purchase successful!",
                    text: "To download original file, go to NFT details page.",
                    html: `<div><div>To download original file, go to NFT details page.</div><br /><div class=thankyougems_title_separates_purchase><h5 class=thankyougemstitles_purchase>You Have Earned  - ${datas}</h5><div class=cong_gems_small_img_purchase>
                    <img src="/assets/gemssimple.svg" alt="Logo"/>
                  </div></div><br /><div class=thankyougems_title_separates_purchase><h5 class=thankyougemstitles_purchase>Current Gems - ${datas + parseFloat(updateGems)}</h5><div class=cong_gems_small_img_purchase>
                  <img src="/assets/gemssimple.svg" alt="Logo"/><br /></div>`,
                    showConfirmButton: false,
                    timer: 1500,
                  });
                  setTimeout(() => {
                    window.location.href = `/profile/${userState?.userProfileData?.public_address}?tab=collected`;
                  }, 2000);
                });
            } catch (err) {
              console.log(err, "err");
              onHide();
              setLoading(false);
              setChecked(false);
              hideTransactionPopup();
              let errorMessage = err?.data?.message ? err?.data?.message : "";
              let isInsufficient = errorMessage.search("insufficient");
              if (
                err.code == "INSUFFICIENT_FUNDS" ||
                parseInt(isInsufficient) > 0
              ) {
                Swal.fire({
                  icon: "warning",
                  title: "Insufficient Funds!",
                  text: "Your transaction is failed due to insufficent funds!",
                  timer: 2500,
                });
              } else {
                Swal.fire({
                  icon: "warning",
                  // title: "Oops...",
                  text: "Your transaction is failed!",
                  timer: 1500,
                });
              }
            }
          }
        }
      } else if (userState?.userProfileData?.wallet_type === "custom") {
        if (checked === false) {
          setShowErrorMessage(true);
        } else {
          try {
            setShowErrorMessage(false);
            setLoading(true);
            showTransactionPopup("NFT purchase in progress", " ", " ");

            const data = {
              tokenIds: tokens,
              blockchain: currency === "MATIC" ? "polygon" : "ethereum",
            };
            const response = await buyCartNft(data);
            if (response.status === 200) {
              onHide();
              setLoading(false);
              setChecked(false);
              hideTransactionPopup();
              let logObj = {};
              logObj.operation = "BOUGHT";
              logObj.token_ids = tokens;
              logObj.tx_hash = response.data.data.transactionHash;
              logObj.blockchain = currency === "MATIC" ? "polygon" : "ethereum";
              let logresp = await cartLogHistory(logObj);
              let totalPrice = cartItem?.reduce(
                (accumulator, currentValue) => {
                  const price = currentValue?.metadata?.converted?.price > 50 ? currentValue?.metadata?.converted?.price : 50
                  return (
                    accumulator + price
                  );
                },
                0
              );
              Swal.fire({
                icon: "success",
                title: "NFT purchase successful!",
                text: "To download original file, go to NFT details page.",
                html: `<div><div>To download original file, go to NFT details page.</div><br />
                <div class=thankyougems_title_separates_purchase><h5 class=thankyougemstitles_purchase>Gas Fee  - ${parseFloat(response?.data?.data?.gas_price).toFixed(5)}</h5><div class=cong_gems_small_img_purchase>
              </div></div><br />
                <div class=thankyougems_title_separates_purchase><h5 class=thankyougemstitles_purchase>You Have Earned  - ${totalPrice}</h5><div class=cong_gems_small_img_purchase>
                <img src="/assets/gemssimple.svg" alt="Logo"/>
              </div></div><br /><div class=thankyougems_title_separates_purchase><h5 class=thankyougemstitles_purchase>Current Gems - ${parseFloat(totalPrice) + parseFloat(updateGems)}</h5><div class=cong_gems_small_img_purchase>
              <img src="/assets/gemssimple.svg" alt="Logo"/><br /></div>`,
                showConfirmButton: false,
                timer: 1500,
              });
              setTimeout(() => {
                window.location.href = `/profile/${userState?.userProfileData?.public_address}?tab=collected`;
              }, 2000);
            } else {
              onHide();
              setLoading(false);
              setChecked(false);
              hideTransactionPopup();
              Swal.fire({
                icon: "error",
                title: response?.data?.message,
                showConfirmButton: false,
                timer: 1500,
              });
            }
          } catch (err) {
            console.log(err, "err");
            onHide();
            setLoading(false);
            setChecked(false);
            hideTransactionPopup();
            let errorMessage = err?.data?.message ? err?.data?.message : "";
            let isInsufficient = errorMessage.search("insufficient");
            if (
              err.code == "INSUFFICIENT_FUNDS" ||
              parseInt(isInsufficient) > 0
            ) {
              Swal.fire({
                icon: "warning",
                title: "Insufficient Funds!",
                text: "Your transaction is failed due to insufficent funds!",
                timer: 2500,
              });
            } else {
              Swal.fire({
                icon: "warning",
                // title: "Oops...",
                text: "Your transaction is failed!",
                timer: 1500,
              });
            }
          }
        }
      }
    }
  };

  return (
    <>
      <Modal
        show={show}
        onHide={() => {
          onHide();
        }}
        {...props}
        // size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton className={`${styles.nomodalborder}`}>
          <Modal.Title id="contained-modal-title-vcenter">
            <h1 className={`${styles.title}`}>Purchase</h1>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className={`${styles.modalbody}`}>
          <div className={`${styles.purchase_box}`}>
            <div className={`${styles.chececkedButtonForm}`}>
              <input
                type="checkbox"
                checked={checked}
                onChange={() => {
                  setChecked(!checked);
                  setShowErrorMessage(false);
                }}
                style={{ appearance: "auto" }}
              />
              <p className={`${styles.agreedform}`}>
                By performing this action, you agree to our
                {/* <br /> */}
                <Link href="">
                  <a> Terms of service&nbsp;</a>
                </Link>
                and
                <Link href="">
                  <a> Privacy Policy</a>
                </Link>
              </p>
            </div>

            <div className={`${styles.purchaselink_separate}`}>
              <h4 className={`${styles.purchaseitem}`}>
                {cartItem?.length} Items
              </h4>

              <a
                className={`${styles.purchaseclearlink}`}
                onClick={() => onRemoveData()}
              >
                Clear All
              </a>
            </div>

            <div className={`${styles.cart_data}`}>
              {cartItem?.length > 0 &&
                cartItem?.map((data, key) => {
                  return (
                    <div className={`${styles.cart_box}`}>
                      <div className={`${styles.cart_img}`}>
                        {data.asset?.file_type?.includes("video/") ? (
                          <Link
                            href={`${
                              data.token &&
                              data?.type !== "gallery" &&
                              data?.type !== "giveaway" &&
                              !(
                                data?.for_giveaway === 1 &&
                                data?.giveaway &&
                                data?.giveaway?.winner_announced === 0 &&
                                data?.metadata?.is_listed == 0
                              )
                                ? `/token/${contract[contractAddress]}:${data.token}`
                                : (data?.for_giveaway === 1 &&
                                    data?.giveaway &&
                                    data?.giveaway?.winner_announced === 0 &&
                                    data?.metadata?.is_listed == 0) ||
                                  data?.type === "giveaway"
                                ? `/giveaway/${contract[contractAddress]}:${data.token}`
                                : data?.type === "gallery"
                                ? `/gallery/${contract[contractAddress]}:${data.token}`
                                : "#"
                            }`}
                          >
                            <a className={`${styles.imageContainer}`}>
                              <Image
                                src={
                                  data.thumbnail
                                    ? data.thumbnail?.home_page_listings
                                    : "/assets/img/covers/cover1.jpg"
                                }
                                width={24}
                                height={24}
                                alt="Cartimg"
                              />
                            </a>
                          </Link>
                        ) : data.asset?.file_type?.includes("audio/") ? (
                          <Link
                            href={`${
                              data.token &&
                              data?.type !== "gallery" &&
                              data?.type !== "giveaway" &&
                              !(
                                data?.for_giveaway === 1 &&
                                data?.giveaway &&
                                data?.giveaway?.winner_announced === 0 &&
                                data?.metadata?.is_listed == 0
                              )
                                ? `/token/${contract[contractAddress]}:${data.token}`
                                : (data?.for_giveaway === 1 &&
                                    data?.giveaway &&
                                    data?.giveaway?.winner_announced === 0 &&
                                    data?.metadata?.is_listed == 0) ||
                                  data?.type === "giveaway"
                                ? `/giveaway/${contract[contractAddress]}:${data.token}`
                                : data?.type === "gallery"
                                ? `/gallery/${contract[contractAddress]}:${data.token}`
                                : "#"
                            }`}
                          >
                            <a className={`${styles.imageContainer}`}>
                              <Image
                                src={
                                  data.thumbnail
                                    ? data.thumbnail?.home_page_listings
                                    : "/assets/img/image-loader.gif"
                                }
                                width={24}
                                height={24}
                                alt="Cartimg"
                              />
                            </a>
                          </Link>
                        ) : (
                          <Link
                            href={`${
                              data.token &&
                              data?.type !== "gallery" &&
                              data?.type !== "giveaway" &&
                              !(
                                data?.for_giveaway === 1 &&
                                data?.giveaway &&
                                data?.giveaway?.winner_announced === 0 &&
                                data?.metadata?.is_listed == 0
                              )
                                ? `/token/${contract[contractAddress]}:${data.token}`
                                : (data?.for_giveaway === 1 &&
                                    data?.giveaway &&
                                    data?.giveaway?.winner_announced === 0 &&
                                    data?.metadata?.is_listed == 0) ||
                                  data?.type === "giveaway"
                                ? `/giveaway/${contract[contractAddress]}:${data.token}`
                                : data?.type === "gallery"
                                ? `/gallery/${contract[contractAddress]}:${data.token}`
                                : "#"
                            }`}
                          >
                            <a className={`${styles.imageContainer}`}>
                              <Image
                                src={
                                  data?.asset
                                    ? data.cardSize === "sm"
                                      ? data.metadata?.is_collection === 0
                                        ? data.asset.src?.home_page_listings
                                        : data.thumbnail?.home_page_listings
                                      : data.metadata?.is_collection === 0
                                      ? data.asset.src?.home_page_listings
                                      : data.thumbnail?.home_page_listings
                                    : "/assets/img/image-loader.gif"
                                }
                                width={24}
                                height={24}
                                alt="Cartimg"
                              />
                            </a>
                          </Link>
                        )}
                      </div>
                      <h2 className={`${styles.cart_title}`}>{data?.title}</h2>
                      <h2 className={`${styles.cart_title2}`}>{data?.metadata?.converted?.price > 50 ? data?.metadata?.converted?.price : 50}<div className={`${styles.current_level_small_img} ml-1`}><Image src="/assets/gemssimple.svg" alt="Logo" layout='fill'/></div></h2>
                    
                    </div>
                  );
                })}
                <div className={`${styles.afterPurchase}`}>After Purchasing you will get {cartItem?.reduce(
                (accumulator, currentValue) => {
                  const price = currentValue?.metadata?.converted?.price > 50 ? currentValue?.metadata?.converted?.price : 50
                  return (
                    accumulator + price
                  );
                },
                0
              )} Gems </div>
            </div>

            <div className={`${styles.price_data}`}>
              <div className={`${styles.price_separate}`}>
                <h4 className={`${styles.totalprice}`}>Your Balance</h4>

                <div className={`${styles.priceseparate}`}>
                  {userState?.userProfileData?.wallet_type === "custom" ? (
                    <>
                      <p className={`${styles.pricevalue}`}>
                        {parseFloat(currentBalance).toFixed(4)}
                      </p>
                      <p className={`${styles.pricelabel}`}>MATIC</p>
                      <p className={`${styles.pricevalue}`}>
                        {parseFloat(walletDetail).toFixed(4)}
                      </p>
                      <p className={`${styles.pricelabel}`}>ETH</p>
                    </>
                  ) : (
                    <>
                      <p className={`${styles.pricevalue}`}>
                        {parseFloat(currentBalance).toFixed(4)}
                      </p>
                      <p className={`${styles.pricelabel}`}>
                        {currentCurrency === "polygon" ? "MATIC" : "ETH"}
                      </p>
                    </>
                  )}
                </div>
              </div>

              <div className={`${styles.price_separate}`}>
                <h4 className={`${styles.totalprice}`}>Price</h4>

                <div className={`${styles.priceseparate}`}>
                  <p className={`${styles.pricevalue}`}>
                    {parseFloat(total).toFixed(4)}
                  </p>
                  <p className={`${styles.pricelabel}`}>{currency}</p>
                </div>
              </div>

              <div className={`${styles.price_separate}`}>
                <h4 className={`${styles.totalprice}`}>Service Fee</h4>

                <div className={`${styles.priceseparate}`}>
                  <p className={`${styles.pricevalue}`}>
                    {parseFloat(serviceFee).toFixed(2)}
                  </p>
                  <p className={`${styles.pricelabel}`}>{currency}</p>
                </div>
              </div>

              <div className={`${styles.price_separate}`}>
                <h4 className={`${styles.totalprice}`}>Total</h4>

                <div className={`${styles.priceseparate}`}>
                  <p className={`${styles.pricevalue}`}>
                    {parseFloat(totalPrice).toFixed(4)}
                  </p>
                  <p className={`${styles.pricelabel}`}>{currency}</p>
                </div>
              </div>

              <div className={`${styles.price_separate}`}>
                <h4 className={`${styles.totalprice}`}>USD</h4>

                <div className={`${styles.priceseparate}`}>
                  <p className={`${styles.pricevalue}`}>
                    {parseFloat(convertedPrice).toFixed(2)}
                  </p>
                  <p className={`${styles.pricelabel}`}>$</p>
                </div>
              </div>
              {userState?.userProfileData?.wallet_type === "custom" && (
                <div className={`${styles.price_separate}`}>
                  <h4 className={`${styles.totalprice}`}>Approx. Gas Fees</h4>
                  <div className={`${styles.priceseparate}`}>
                    <p className={`${styles.pricevalue}`}>
                      {currency === "MATIC" ? "0.05" : "0.007"}
                    </p>
                    <p className={`${styles.pricelabel}`}>
                      {currency === "MATIC" ? "MATIC" : "ETH"}
                    </p>
                  </div>
                </div>
              )}
              {userState?.userProfileData?.wallet_type === "custom" &&
                (currency === "MATIC"
                  ? currentBalance < parseFloat(parseFloat(total) + 0.05)
                  : currency === "ETH" &&
                    currentBalance < parseFloat(parseFloat(total) + 0.07)) && (
                  <h4 className={`${styles.bal_info}`}>
                    Your balance is insufficient
                  </h4>
                )}
              {showErrorMsg && (
                <small className={`text-danger`}>
                  <br />
                  {`Please agree with our terms of service & privacy policy`}
                </small>
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className={`${styles.nomodalborder} mb-4`}>
          {!onloading ? (
            <div>
              <Button
                varient={"primary"}
                type="submit"
                onClick={() => onPurchaseItem()}
                className={userState?.userProfileData?.wallet_type === "custom" &&
                (currency === "MATIC"
                  ? currentBalance < parseFloat(parseFloat(total) + 0.05)
                  : currency === "ETH" &&
                    currentBalance < parseFloat(parseFloat(total) + 0.07)) ? `${styles.disable_btn}` : ''}

                disabled = {userState?.userProfileData?.wallet_type === "custom" &&
                (currency === "MATIC"
                  ? currentBalance < parseFloat(parseFloat(total) + 0.05)
                  : currency === "ETH" &&
                    currentBalance < parseFloat(parseFloat(total) + 0.07))}
              >
                Purchase
              </Button>
              <Button varient={"secondary_nooutline"} onClick={() => onHide()}>
                Cancel
              </Button>
            </div>
          ) : (
            <div style={{ textAlign: "center" }}>
              <Loader
                type="ThreeDots"
                color="#7557BB"
                height={50}
                width={50}
                timeout={3000000} //3 secs
              />
            </div>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PurchaseModal;
